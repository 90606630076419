export const filterSectionVariants = [
  {
    name: "Big Map",
    markdown: `{% inlineRouterLink articleId="survey-properties" %}Survey properties{% /inlineRouterLink %} on Big Map to measure the size of a market and see an actionable list of properties matching the filter criteria.

    ![Big Map filter screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
  {
    name: "Property page",
    markdown: `Measure the size of selected property rights and land coverings in any {% inlineRouterLink %}property diagram{% /inlineRouterLink %}.

    ![Property page filter screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
  {
    name: "Company/contact page",
    markdown: `Measure the combined size of the property components that a {% inlineRouterLink %}company{% /inlineRouterLink %} or {% inlineRouterLink %}contact{% /inlineRouterLink %} is involved with.

    ![Company/contact page filter screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
];

export const theRoleOfTheAnalyzeTab = {
  order: 0,
  name: "The role of the Analyze tab",
  subcategory: "Analyze datapoints",
  markdown: `# The role of the Analyze tab
  As its name suggests, the Analyze tab helps with analysis. When you want to summarize, calculate, aggregate, filter, and sort collections of datapoints, the Analyze tab is usually where this happens. It is located in the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %}. Read on for an overview of its sections.

  ## Filter

  The {% inlineAppIcon iconName="filter" %}**Filter**{% /inlineAppIcon %} section can be found in {% inlineRouterLink %}Big Map{% /inlineRouterLink %}, {% inlineRouterLink %}property pages{% /inlineRouterLink %}, {% inlineRouterLink %}company pages{% /inlineRouterLink %}, and {% inlineRouterLink %}contact pages{% /inlineRouterLink %}. It is a tool for summarizing and listing a collection of datapoints that meet certain criteria.

  {% tabbedContainer tabs=$filterSectionVariants /%}`,
};
